import routeFor from 'helpers/routeFor';
import cx from 'classnames';
import routes from 'helpers/routes';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import UgcRequest from 'types/UgcRequest';
import StatusPill from 'components/StatusPill';
import getTypeFromUgcRequest from 'helpers/getTypeFromUgcRequest';

interface Props {
  ugcRequest: UgcRequest;
}

export default function UgcRequestsSidebarItem({ ugcRequest }: Props) {
  const id = parseInt(useParams<{ id?: string }>().id || '', 10);
  const isActive = ugcRequest.id === id;
  const { t } = useTranslation();

  return (
    <Link
      className={cx('flex items-center p-1 rounded-xl ', {
        'bg-grey8': isActive,
        'hover:bg-grey9': !isActive,
      })}
      to={routeFor(
        routes.requests[ugcRequest.status === 'draft' ? 'edit' : 'show'],
        getTypeFromUgcRequest(ugcRequest),
        ugcRequest.id
      )}
    >
      <div className="flex-1 mr-3 overflow-hidden">
        <div className="text-dark text-14 w-full overflow-hidden font-semibold truncate">
          {ugcRequest.subject || t('global.noSubject')}
        </div>

        <div
          className={cx(
            'text-12 mb-0.5 last:mb-0',
            isActive ? 'text-dark' : 'text-light'
          )}
        >
          /{ugcRequest.urlSlug}
        </div>

        {(!ugcRequest.expiresAt ||
          ugcRequest.expiresAt > new Date().getTime() ||
          ugcRequest.status === 'draft') && (
          <StatusPill ugcRequest={ugcRequest} size="small" />
        )}
      </div>

      {ugcRequest.ugcSubmissionsCount && (
        <div className="border-default rounded-full h-2.5 px-0.5 bg-white text-dark text-12 font-bold min-w-4 text-center">
          <span className="bump-up-1">{ugcRequest.ugcSubmissionsCount}</span>
        </div>
      )}
    </Link>
  );
}
