import useTranslateErrors from 'hooks/useTranslateErrors';
import { useTranslation } from 'react-i18next';
import UgcRequest from 'types/UgcRequest';
import UgcRequestParams from 'types/UgcRequestParams';
import ValidationErrorPayload from 'types/ValidationErrorPayload';
import Form from 'components/Form';
import UrlSlug from './UrlSlug';
import MediaType from './MediaType';
import Orientation from './Orientation';
import VideoDuration from './VideoDuration';
import TalkingPoints from './TalkingPoints';
import Expiration from './Expiration';
import Account from 'types/Account';
import DataCapture from './DataCapture';
import usePlan from 'hooks/usePlan';

interface Props {
  ugcRequest: UgcRequest;
  values: UgcRequestParams;
  onChange: (values: Partial<UgcRequestParams>) => void;
  onChangeDataUrl: (
    changes: Partial<{
      coverPhoto?: string | null;
      featuredContent?: string | null;
    }>
  ) => void;
  errors?: ValidationErrorPayload<UgcRequest> | null;
  currentAccount: Account;
  onUploadStart: () => void;
  onUploadStop: () => void;
}

export default function UgcRequestFormFields(props: Props) {
  const {
    ugcRequest,
    values,
    onChange,
    onChangeDataUrl,
    errors,
    currentAccount,
    onUploadStart,
    onUploadStop,
  } = props;
  const { t } = useTranslation();
  const plan = usePlan();
  const translateErrors = useTranslateErrors();

  return (
    <div className="mx-auto w-full max-w-54">
      {!!errors && (
        <div className="notice notice--error">
          {t('ugcRequestBuilder.form.errorNotice')}
        </div>
      )}

      <UrlSlug
        requestId={ugcRequest.id}
        brand={currentAccount.brand?.toLowerCase()}
        value={values.urlSlug}
        onChange={(urlSlug) => onChange({ urlSlug })}
      />

      <Form.Section
        title={t('ugcRequestBuilder.form.media.heading')}
        description={t('ugcRequestBuilder.form.media.description')}
      >
        <MediaType
          value={values.mediaType}
          errors={translateErrors(errors?.mediaType)}
          onChange={(mediaType) =>
            onChange({
              mediaType,
              talkingPoints:
                mediaType === 'video' && !values.talkingPoints?.length
                  ? ['']
                  : values.talkingPoints,
            })
          }
        />

        <Orientation
          value={values.orientation}
          errors={translateErrors(errors?.orientation)}
          onChange={(orientation) => onChange({ orientation })}
        />

        {['video', 'both'].includes(values.mediaType) && (
          <VideoDuration
            errors={translateErrors(errors?.videoDuration)}
            value={values.videoDuration}
            onChange={(videoDuration) => onChange({ videoDuration })}
          />
        )}
      </Form.Section>

      <Form.Section
        title={t('ugcRequestBuilder.form.requestDetails.heading')}
        description={t('ugcRequestBuilder.form.requestDetails.description')}
      >
        <Form.TextInput
          name="subject"
          required
          label={t('ugcRequestBuilder.form.subject.label')}
          placeholder={t('ugcRequestBuilder.form.subject.placeholder')}
          value={values.subject}
          onChange={(e) => onChange({ subject: e.target.value })}
          errors={translateErrors(errors?.subject)}
          maxLength={80}
        />

        <Form.TextArea
          name="instructions"
          required
          label={t('ugcRequestBuilder.form.instructions.label')}
          placeholder={t('ugcRequestBuilder.form.instructions.placeholder')}
          value={values.instructions}
          onChange={(e) => onChange({ instructions: e.target.value })}
          rows={3}
          errors={translateErrors(errors?.instructions)}
          maxLength={500}
          autoSize
        />

        {(values.mediaType === 'video' || values.mediaType === 'both') && (
          <>
            <Form.TextInput
              name="talkingPointsTitle"
              errors={translateErrors(errors?.talkingPointsTitle)}
              label={t('ugcRequestBuilder.form.talkingPointsTitle.label')}
              placeholder={t(
                'ugcRequestBuilder.form.talkingPointsTitle.placeholder'
              )}
              value={values.talkingPointsTitle}
              onChange={(e) => onChange({ talkingPointsTitle: e.target.value })}
              maxLength={160}
              required={Boolean(
                values.talkingPoints?.length &&
                  values.talkingPoints.join(',').trim().length
              )}
            />
            <TalkingPoints
              value={values.talkingPoints}
              onChange={(value) => onChange({ talkingPoints: value })}
            />
          </>
        )}
      </Form.Section>

      <Form.Section
        title={t('ugcRequestBuilder.form.coverImage.heading')}
        description={t('ugcRequestBuilder.form.coverImage.description')}
      >
        <div className="mb-3">
          <Form.FileInput
            aspectRatio={1}
            errors={translateErrors(errors?.coverPhoto)}
            extensions={['jpg', 'jpeg', 'png']}
            attachment={{
              src: ugcRequest.coverPhotoUrl,
              contentType: ugcRequest.coverPhotoContentType,
            }}
            onSelectFile={(dataUrl) => onChangeDataUrl({ coverPhoto: dataUrl })}
            onUploadStart={() => onUploadStart()}
            onUploadSuccess={() => onUploadStop()}
            onUploadFailure={() => onUploadStop()}
            onChange={(coverPhoto) => onChange({ coverPhoto })}
          />
        </div>

        <Form.ColorPicker
          label={t('settings.brand.backgroundColor.label')}
          description={t('settings.brand.backgroundColor.description')}
          value={values.backgroundColor}
          onChange={(value) => onChange({ backgroundColor: value })}
        />

        <Form.Field label={t('settings.brand.backgroundOverlayOpacity.label')}>
          <div className="flex items-start space-x-0.5 text-light">
            <span className="text-14 text-dark bump-down-2">0%</span>

            <div className="flex-1 bump-up-2 text-center">
              <input
                type="range"
                step={1}
                max={100}
                className="range-input"
                value={values.backgroundOverlayOpacity * 100}
                onChange={(e) =>
                  onChange({
                    backgroundOverlayOpacity:
                      parseInt(e.target.value, 10) / 100,
                  })
                }
              />

              <span className="text-dark text-12">
                {Math.round(values.backgroundOverlayOpacity * 100)}%
              </span>
            </div>

            <span className="text-14 text-dark bump-down-2">100%</span>
          </div>
        </Form.Field>

        <Form.Field label={t('settings.brand.backgroundBlurAmount.label')}>
          <div className="flex items-start space-x-0.5 text-light">
            <span className="text-14 text-dark bump-down-2">0%</span>

            <div className="flex-1 bump-up-2 text-center">
              <input
                type="range"
                step={1}
                max={48}
                className="range-input"
                value={parseInt(values.backgroundBlurAmount, 10)}
                onChange={(e) =>
                  onChange({
                    backgroundBlurAmount: `${e.target.value}px`,
                  })
                }
              />

              <span className="text-dark text-12">
                {Math.ceil(
                  (parseInt(values.backgroundBlurAmount, 10) / 48) * 100
                )}
                %
              </span>
            </div>

            <span className="text-14 text-dark bump-down-2">100%</span>
          </div>
        </Form.Field>
      </Form.Section>

      <Form.Section
        title={t('ugcRequestBuilder.form.featuredContent.heading')}
        description={t('ugcRequestBuilder.form.featuredContent.description')}
      >
        <Form.Field>
          <Form.FileInput
            extensions={['jpg', 'jpeg', 'png', 'mp4']}
            errors={translateErrors(errors?.featuredContent)}
            attachment={{
              src: ugcRequest.featuredContentUrl,
              contentType: ugcRequest.featuredContentContentType,
            }}
            onSelectFile={(dataUrl) =>
              onChangeDataUrl({ featuredContent: dataUrl })
            }
            onChange={(featuredContent) => onChange({ featuredContent })}
            onUploadStart={() => onUploadStart()}
            onUploadSuccess={() => onUploadStop()}
            onUploadFailure={() => onUploadStop()}
          />
        </Form.Field>

        <Form.TextInput
          name="featuredContentTitle"
          errors={translateErrors(errors?.featuredContentTitle)}
          label={t('ugcRequestBuilder.form.featuredContentTitle.label')}
          placeholder={t(
            'ugcRequestBuilder.form.featuredContentTitle.placeholder'
          )}
          value={values.featuredContentTitle}
          onChange={(e) => onChange({ featuredContentTitle: e.target.value })}
          maxLength={80}
        />

        <Form.TextArea
          name="featuredContentDescription"
          errors={translateErrors(errors?.featuredContentDescription)}
          label={t('ugcRequestBuilder.form.featuredContentDescription.label')}
          placeholder={t(
            'ugcRequestBuilder.form.featuredContentDescription.placeholder'
          )}
          value={values.featuredContentDescription}
          onChange={(e) =>
            onChange({ featuredContentDescription: e.target.value })
          }
          rows={3}
          maxLength={180}
          autoSize
        />
      </Form.Section>

      <Form.Section
        title={t('ugcRequestBuilder.form.intro.heading')}
        description={t('ugcRequestBuilder.form.intro.description')}
      >
        <Form.TextInput
          name="introHeader"
          required
          errors={translateErrors(errors?.introHeader)}
          label={t('ugcRequestBuilder.form.introHeader.label')}
          placeholder={t('ugcRequestBuilder.form.introHeader.placeholder')}
          value={values.introHeader}
          onChange={(e) => onChange({ introHeader: e.target.value })}
          maxLength={80}
        />

        <Form.TextArea
          name="introDescription"
          required
          errors={translateErrors(errors?.introDescription)}
          label={t('ugcRequestBuilder.form.introDescription.label')}
          placeholder={t('ugcRequestBuilder.form.introDescription.placeholder')}
          value={values.introDescription}
          onChange={(e) => onChange({ introDescription: e.target.value })}
          rows={3}
          maxLength={180}
          autoSize
        />

        <Form.TextInput
          name="introButtonText"
          errors={translateErrors(errors?.introButtonText)}
          required
          label={t('ugcRequestBuilder.form.introButtonText.label')}
          placeholder={t('ugcRequestBuilder.form.introButtonText.placeholder')}
          value={values.introButtonText}
          onChange={(e) => onChange({ introButtonText: e.target.value })}
          maxLength={20}
        />
      </Form.Section>

      <Expiration
        errors={translateErrors(errors?.expiresAts)}
        value={values.expiresAt}
        onChange={(expiresAt) => onChange({ expiresAt })}
      />

      {!!plan.sponsor && (
        <Form.Section title={t('settings.brand.sponsor.heading')}>
          <Form.TextInput
            label={t('settings.brand.sponsorName.label')}
            value={values.sponsorName}
            errors={translateErrors(errors?.sponsorName)}
            onChange={(e) => onChange({ sponsorName: e.target.value })}
          />

          <Form.Field label={t('settings.brand.sponsorLogo.label')}>
            <Form.FileInput
              aspectRatio={1}
              extensions={['jpg', 'jpeg', 'png']}
              thumbnailSize="small"
              borderRadius={9999}
              attachment={{
                src: ugcRequest.sponsorLogoUrl,
                contentType: ugcRequest.sponsorLogoContentType,
              }}
              onChange={(sponsorLogo) => onChange({ sponsorLogo })}
              onUploadStart={() => onUploadStart()}
              onUploadSuccess={() => onUploadStop()}
              onUploadFailure={() => onUploadStop()}
            />
          </Form.Field>
        </Form.Section>
      )}

      <DataCapture
        value={values.dataCapture}
        onChange={(dataCapture) => onChange({ dataCapture })}
      />
    </div>
  );
}
