import { useUpdateUgcRequestMutation } from 'api/ugcRequests';
import camelize from 'camelize';
import UgcRequestPreview from 'components/UgcRequestPreview';
import ProtectUnsavedChanges from 'components/ProtectUnsavedChanges';
import RequestLimitModal from 'components/RequestLimitModal';
import useFlashMessages from 'hooks/useFlashMessages';
import useStateWithPromise from 'hooks/useStateWithPromise';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { selectCurrentUser } from 'redux/reducers/auth';
import { useAppSelector } from 'redux/store';
import UgcRequest from 'types/UgcRequest';
import UgcRequestParams from 'types/UgcRequestParams';
import ValidationErrorPayload from 'types/ValidationErrorPayload';
import { UgcRequestFormFields, UgcRequestFormTopBar } from './components';

interface Props {
  ugcRequest: UgcRequest;
  context?: 'builder' | 'edit';
}

export interface UgcRequestFormSaveOptions {
  onSuccess?: () => void;
  onError?: () => void;
  values?: Partial<UgcRequestParams>;
  trigger?:
    | 'save-now'
    | 'publish'
    | 'save+close'
    | 'save-draft'
    | 'save+preview'
    | 'any'
    | null;
}

export default function UgcRequestForm(props: Props) {
  const { ugcRequest, context = 'builder' } = props;

  // Track which button triggered a save
  const [saveTriggeredBy, setSaveTriggeredBy] =
    useState<UgcRequestFormSaveOptions['trigger']>(null);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useStateWithPromise(false);

  const [values, setValues] = useState(buildDefaultValues(ugcRequest));

  const [dataUrls, setDataUrls] = useState({
    coverPhoto: ugcRequest.coverPhotoUrl as string | null,
    featuredContent: ugcRequest.featuredContentUrl as string | null,
  });

  const currentAccount = useAppSelector(selectCurrentUser)?.currentAccount;

  const { t } = useTranslation();

  const [updateUgcRequest] = useUpdateUgcRequestMutation();

  const [errors, setErrors] =
    useState<null | ValidationErrorPayload<UgcRequest>>(null);

  const [isRequestLimitModalOpen, setIsRequestLimitModalOpen] = useState(false);

  const [activeUploadCount, setActiveUploadCount] = useState(0);

  const { addFlashMessage } = useFlashMessages();

  const handleChange = async (changes: Partial<UgcRequestParams>) => {
    setHasUnsavedChanges(true);
    setValues((values) => ({ ...values, ...changes }));
  };

  const handleChangeDataUrl = (changes: Partial<typeof dataUrls>) => {
    setDataUrls((dataUrls) => ({ ...dataUrls, ...changes }));
  };

  const handleSave = async (options: UgcRequestFormSaveOptions = {}) => {
    setErrors(null);
    setSaveTriggeredBy(options.trigger || 'any');
    const nextValues = { ...values, ...options.values };
    setValues(nextValues);
    const result = await updateUgcRequest({
      id: ugcRequest.id,
      values: nextValues,
    });
    setSaveTriggeredBy(null);

    if ('error' in result) {
      if ('data' in result.error && result.error.status === 422) {
        if (
          result.error.data.errors.base?.[0] ===
          'validation.requestLimitReached'
        ) {
          setIsRequestLimitModalOpen(true);
        } else {
          window.scroll({ top: 0, behavior: 'smooth' });
          setErrors(camelize(result.error.data.errors));
        }
      } else {
        addFlashMessage(t('global.unexpectedError'), {
          isError: true,
          timeout: false,
        });
      }
      options.onError?.();
    } else {
      addFlashMessage(t('ugcRequestBuilder.messages.success'));
      setHasUnsavedChanges(false);
      options.onSuccess?.();
    }
  };

  if (!currentAccount) return null;

  return (
    <div>
      <Helmet title={t(`ugcRequestBuilder.pageTitles.${context}`)} />
      <UgcRequestFormTopBar
        ugcRequest={ugcRequest}
        values={values}
        context={context}
        onSave={handleSave}
        saveTriggeredBy={saveTriggeredBy}
        disableUnsavedChangesPrompt={() => setHasUnsavedChanges(false)}
        hasUnsavedChanges={hasUnsavedChanges}
        activeUploadCount={activeUploadCount}
      />

      <div className="flex items-stretch bg-white min-h-screen">
        <div className="flex-1 px-2 py-5">
          <UgcRequestFormFields
            values={values}
            onChange={handleChange}
            onChangeDataUrl={handleChangeDataUrl}
            ugcRequest={ugcRequest}
            errors={errors}
            currentAccount={currentAccount}
            onUploadStart={() => setActiveUploadCount((i) => i + 1)}
            onUploadStop={() => setActiveUploadCount((i) => i - 1)}
          />
        </div>

        <div className="flex-1 bg-grey9 py-5">
          <UgcRequestPreview
            values={values}
            dataUrls={dataUrls}
            account={currentAccount}
          />
        </div>
      </div>

      {hasUnsavedChanges && (
        <ProtectUnsavedChanges msg={t('ugcRequestBuilder.form.prompt')} />
      )}

      <RequestLimitModal
        isOpen={isRequestLimitModalOpen}
        onRequestClose={() => setIsRequestLimitModalOpen(false)}
      />
    </div>
  );
}

// Merge the default values with any non-null values from the ugcRequest
function buildDefaultValues(ugcRequest: UgcRequest): UgcRequestParams {
  const defaultValues: UgcRequestParams = {
    status: 'draft',
    urlSlug: '',
    subject: '',
    instructions: '',
    talkingPointsTitle: '',
    talkingPoints: [''],
    mediaType: '',
    orientation: '',
    introHeader: '',
    introDescription: '',
    introButtonText: '',
    featuredContentTitle: '',
    featuredContentDescription: '',
    backgroundColor: '',
    sponsorName: '',
    backgroundBlurAmount: '0px',
    backgroundOverlayOpacity: 0.0,
    videoDuration: null,
    expiresAt: null,
    dataCapture: null,
  };

  return {
    ...defaultValues,
    ...(Object.keys(defaultValues) as (keyof UgcRequest)[])
      .filter((k) => ugcRequest[k] != null)
      .reduce((a, k) => ({ ...a, [k]: ugcRequest[k] }), {}),
  };
}
